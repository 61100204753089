define("discourse/plugins/discourse-docs/discourse/controllers/docs", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    indexController: (0, _controller.inject)("docs.index"),
    updateSelectedCategories(category) {
      this.indexController.send("updateSelectedCategories", category);
      return false;
    },
    updateSelectedTags(tag) {
      this.indexController.send("updateSelectedTags", tag);
      return false;
    },
    updateSelectedGroups(group) {
      this.indexController.send("updateSelectedGroups", group);
      return false;
    },
    performSearch(term) {
      this.indexController.send("performSearch", term);
      return false;
    }
  }, [["method", "updateSelectedCategories", [_object.action]], ["method", "updateSelectedTags", [_object.action]], ["method", "updateSelectedGroups", [_object.action]], ["method", "performSearch", [_object.action]]]));
});