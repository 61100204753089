define("discourse/plugins/discourse-docs/discourse/raw-templates/docs-topic-link", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<a data-topic-id=\"" + container.escapeExpression(lookupProperty(helpers, "get").call(alias1, "topic.id", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 18
          },
          "end": {
            "line": 1,
            "column": 30
          }
        }
      })) + "\" class=\"docs-topic-link\">" + ((stack1 = lookupProperty(helpers, "get").call(alias1, "topic.fancyTitle", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 56
          },
          "end": {
            "line": 1,
            "column": 78
          }
        }
      })) != null ? stack1 : "") + "</a>\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/docs-topic-link", template, {
    core: true
  });
  var _default = _exports.default = template;
});