define("discourse/plugins/discourse-docs/discourse/initializers/setup-docs", ["exports", "discourse/lib/plugin-api", "I18n"], function (_exports, _pluginApi, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initialize(api, container) {
    const siteSettings = container.lookup("site-settings:main");
    if (!siteSettings.docs_enabled) {
      return;
    }
    api.decorateWidget("hamburger-menu:generalLinks", () => {
      return {
        route: "docs",
        label: "docs.title",
        className: "docs-link"
      };
    });
    api.addKeyboardShortcut("g e", "", {
      path: "/explorer"
    });
    if (siteSettings.docs_add_to_top_menu) {
      api.addNavigationBarItem({
        name: "docs",
        displayName: _I18n.default.t("docs.title"),
        href: "/explorer"
      });
    }
  }
  var _default = _exports.default = {
    name: "setup-docs",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8", api => initialize(api, container));
      (0, _pluginApi.withPluginApi)("0.12.6", api => {
        const siteSettings = container.lookup("site-settings:main");
        if (!siteSettings.docs_enabled) {
          return;
        }
        api.addSearchSuggestion("in:docs");
        const tip = {
          label: "in:docs",
          description: _I18n.default.t("docs.search.tip_description"),
          clickable: true,
          searchTopics: true
        };
        api.addQuickSearchRandomTip(tip);
      });
    }
  };
});