define("discourse/plugins/discourse-docs/discourse/components/docs-topic-list", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _component, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "docs-topic-list",
    sortTitle(order) {
      return order === "title";
    },
    sortActivity(order) {
      return order === "activity";
    },
    // need to handle clicks here since links are in a raw view
    click(e) {
      if (e.target.classList.contains("docs-topic-link")) {
        const topicId = e.target.dataset.topicId;
        this.selectTopic(topicId);
        return false;
      }
    },
    sortListActivity() {
      this.sortBy("activity");
      return false;
    },
    sortListTitle() {
      this.sortBy("title");
      return false;
    }
  }, [["method", "sortTitle", [(0, _decorators.default)("order")]], ["method", "sortActivity", [(0, _decorators.default)("order")]], ["method", "sortListActivity", [_object.action]], ["method", "sortListTitle", [_object.action]]]));
});