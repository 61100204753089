define("discourse/plugins/discourse-docs/discourse/components/docs-topic", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators", "discourse-common/lib/debounce"], function (_exports, _component, _computed, _decorators, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "docs-topic",
    originalPostContent: (0, _computed.reads)("post.cooked"),
    post(stream) {
      return this.store.createRecord("post", stream?.posts.firstObject);
    },
    model() {
      const post = this.post;
      if (!post.topic) {
        post.set("topic", this.topic);
      }
      return post;
    },
    _emitScrollEvent() {
      this.appEvents.trigger("docs-topic:current-post-scrolled");
    },
    debounceScrollEvent() {
      (0, _debounce.default)(this, this._emitScrollEvent, 200);
    },
    didInsertElement() {
      this._super(...arguments);
      document.querySelector("body").classList.add("archetype-docs-topic");
      document.addEventListener("scroll", this.debounceScrollEvent);
    },
    willDestroyElement() {
      this._super(...arguments);
      document.querySelector("body").classList.remove("archetype-docs-topic");
      document.removeEventListener("scroll", this.debounceScrollEvent);
    }
  }, [["method", "post", [(0, _decorators.default)("topic.post_stream")]], ["method", "model", [(0, _decorators.default)("post", "topic")]], ["method", "_emitScrollEvent", [_decorators.bind]], ["method", "debounceScrollEvent", [_decorators.bind]]]));
});