define("discourse/plugins/discourse-docs/discourse/components/docs-search", ["exports", "@ember/component", "@ember/runloop", "@ember/object", "discourse-common/lib/debounce"], function (_exports, _component, _runloop, _object, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "docs-search",
    debouncedSearch(term) {
      // TODO: Use discouseDebounce when discourse 2.7 gets released.
      const debounceFunc = _debounce.default || _runloop.debounce;
      debounceFunc(this, "onSearch", term, 500);
    },
    onSearchTermChange(term) {
      this.debouncedSearch(term);
    },
    clearSearch() {
      this.set("searchTerm", "");
      this.onSearch("");
    }
  }, [["method", "onSearchTermChange", [_object.action]], ["method", "clearSearch", [_object.action]]]));
});