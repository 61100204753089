define("discourse/plugins/discourse-docs/discourse/models/docs", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/models/topic"], function (_exports, _object, _ajax, _topic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Docs = _object.default.extend({});
  Docs.reopenClass({
    list(params) {
      let filters = [];
      if (params.filterCategories) {
        filters.push(`category=${params.filterCategories}`);
      }
      if (params.filterTags) {
        filters.push(`tags=${params.filterTags}`);
      }
      if (params.filterGroups) {
        filters.push(`groups=${params.filterGroups}`);
      }
      if (params.filterSolved) {
        filters.push(`solved=${params.filterSolved}`);
      }
      if (params.searchTerm) {
        filters.push(`search=${params.searchTerm}`);
      }
      if (params.ascending) {
        filters.push("ascending=true");
      }
      if (params.orderColumn) {
        filters.push(`order=${params.orderColumn}`);
      }
      if (params.page) {
        filters.push(`page=${params.page}`);
      }
      if (params.selectedTopic) {
        filters.push(`topic=${params.selectedTopic}`);
        filters.push("track_visit=true");
      }
      if (params.timeRange) {
        filters.push(`time=${params.timeRange}`);
      }
      return (0, _ajax.ajax)(`/explorer.json?${filters.join("&")}`).then(data => {
        data.topics.topic_list.topics = data.topics.topic_list.topics.map(topic => _topic.default.create(topic));
        data.topic = _topic.default.create(data.topic);
        return data;
      });
    },
    loadMore(loadMoreUrl) {
      return (0, _ajax.ajax)(loadMoreUrl).then(data => {
        data.topics.topic_list.topics = data.topics.topic_list.topics.map(topic => _topic.default.create(topic));
        return data;
      });
    }
  });
  var _default = _exports.default = Docs;
});